var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container pb-6" },
    [
      _c("p", { staticClass: "pb-5" }, [_vm._v("Development tools")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.onboardingCompleted,
                callback: function ($$v) {
                  _vm.onboardingCompleted = $$v
                },
                expression: "onboardingCompleted",
              },
            },
            [_vm._v("Stripe verified")]
          ),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.stripeConnectVisited,
                callback: function ($$v) {
                  _vm.stripeConnectVisited = $$v
                },
                expression: "stripeConnectVisited",
              },
            },
            [_vm._v("Stripe visited")]
          ),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.stripeRequiresInfo,
                callback: function ($$v) {
                  _vm.stripeRequiresInfo = $$v
                },
                expression: "stripeRequiresInfo",
              },
            },
            [_vm._v("Stripe requires info")]
          ),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.stripeRequiresDocument,
                callback: function ($$v) {
                  _vm.stripeRequiresDocument = $$v
                },
                expression: "stripeRequiresDocument",
              },
            },
            [_vm._v("Stripe requires documents")]
          ),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.detailsSubmitted,
                callback: function ($$v) {
                  _vm.detailsSubmitted = $$v
                },
                expression: "detailsSubmitted",
              },
            },
            [_vm._v("Details submitted")]
          ),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              attrs: { size: "is-small" },
              model: {
                value: _vm.oneFlowSigned,
                callback: function ($$v) {
                  _vm.oneFlowSigned = $$v
                },
                expression: "oneFlowSigned",
              },
            },
            [_vm._v("OneFlow signed")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _vm.$store.state.company.qrCode
            ? _c(
                "b-button",
                {
                  attrs: {
                    tag: "button",
                    type: "is-link is-small has-text-weight-bold",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showQrCode = true
                    },
                  },
                },
                [_vm._v("Show OneFlow QR code")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _c(
            "b-button",
            {
              attrs: {
                tag: "button",
                type: "is-link is-small has-text-weight-bold",
              },
              on: {
                click: function ($event) {
                  return _vm.clearContractId()
                },
              },
            },
            [_vm._v("Clear OneFlow Status")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showQrCode },
          on: {
            "update:active": function ($event) {
              _vm.showQrCode = $event
            },
          },
        },
        [
          _vm.$store.state.company.qrCode
            ? _c("img", {
                attrs: {
                  src:
                    "data:image/png;base64," + _vm.$store.state.company.qrCode,
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }