import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
  loggedIn: false,
  userType: '',
  code: '',
  country: '',
  email: '',
  jwt: '',
  userId: '',
  phone: '',
});

export const getters = {
  getField,
};

export const mutations = {
  SET_FORM_DATA(s, data) {
    s.signupForm = Object.assign({}, s.signupForm, data);
  },
  setJwt(s, jwt) {
    s.jwt = jwt;
  },
  setCountry(s, country) {
    s.country = country;
  },
  setUserType(s, userType) {
    s.userType = userType;
  },
  setLoggedIn(s, loggedIn) {
    s.loggedIn = loggedIn;
  },
  setUserId(s, userId) {
    s.userId = userId;
  },
  updateField,
};

export const actions = {
  async requestCode({ commit, state }, formData) {
    const { phone, country } = state;

    try {
      await this.$axios.$post(
        '/api/login/code/request',
        {
          phone,
          locale: country,
          captchaToken: state.captchaToken,
        },
        { headers: { 'x-tiptapp-country': state.country } }
      );
      this.$router.push({ path: 'login' });
    } catch (error) {
      this.$router.push({ path: 'error' });
    }
  },
  async verifyCode({ commit, state }, formData) {
    const { phone, code } = state;

    try {
      const { authorization, userId } = await this.$axios.$post(
        '/api/login/code/verify',
        {
          phone,
          code,
          userKind: 'company',
        },
        { headers: { 'x-tiptapp-country': state.country } }
      );
      commit('setJwt', authorization, { root: true });
      commit('setUserId', userId, { root: true });
      this.$router.push({ path: 'signup' });
    } catch (error) {
      this.$router.push({ path: 'error' });
    }
  },
  async getCountry({ commit, state }, formData) {
    if (state.country) {
      return;
    }

    try {
      const { countryCode } = await this.$axios.$get('/api/get-country');
      commit('setCountry', countryCode);
    } catch (error) {
      console.log(error);
    }
  },
};
