import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ff449146 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _54af3552 = () => interopDefault(import('../pages/company-details.vue' /* webpackChunkName: "pages/company-details" */))
const _fb868ada = () => interopDefault(import('../pages/company-success.vue' /* webpackChunkName: "pages/company-success" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5db6bd30 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _45b33ce9 = () => interopDefault(import('../pages/individual.vue' /* webpackChunkName: "pages/individual" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _531287e3 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _5c83862e = () => interopDefault(import('../pages/test-static.vue' /* webpackChunkName: "pages/test-static" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company",
    component: _ff449146,
    name: "company___en___default"
  }, {
    path: "/company-details",
    component: _54af3552,
    name: "company-details___en___default"
  }, {
    path: "/company-success",
    component: _fb868ada,
    name: "company-success___en___default"
  }, {
    path: "/en",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/error",
    component: _5db6bd30,
    name: "error___en___default"
  }, {
    path: "/individual",
    component: _45b33ce9,
    name: "individual___en___default"
  }, {
    path: "/login",
    component: _57062649,
    name: "login___en___default"
  }, {
    path: "/success",
    component: _531287e3,
    name: "success___en___default"
  }, {
    path: "/test-static",
    component: _5c83862e,
    name: "test-static___en___default"
  }, {
    path: "/en/company",
    component: _ff449146,
    name: "company___en"
  }, {
    path: "/en/company-details",
    component: _54af3552,
    name: "company-details___en"
  }, {
    path: "/en/company-success",
    component: _fb868ada,
    name: "company-success___en"
  }, {
    path: "/en/error",
    component: _5db6bd30,
    name: "error___en"
  }, {
    path: "/en/individual",
    component: _45b33ce9,
    name: "individual___en"
  }, {
    path: "/en/login",
    component: _57062649,
    name: "login___en"
  }, {
    path: "/en/success",
    component: _531287e3,
    name: "success___en"
  }, {
    path: "/en/test-static",
    component: _5c83862e,
    name: "test-static___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
