import { Context } from '@nuxt/types';
import VuexPersistence from 'vuex-persist';

export default ({ store }: Context) => {
  new VuexPersistence({
    storage: sessionStorage,
    filter: (mutation) =>
      [
        'updateField',
        'setUserId',
        'individual/updateField',
        'company/updateField',
        'company/setAccountLink',
        'company/setOneFlowContractId',
        'company/setStripeOnboardingCompleted',
        'company/setStripeRequiresInfo',
        'company/setStripeRequiresDocument',
        'company/setDetailsSubmitted',
        'company/setStripeConnectVisited',
      ].includes(mutation.type),
    reducer: ({
      // skip storing heavy images
      individual: { userImage, imageIdFront, imageIdBack, ...individual },
      company: {
        // skip storing debug page fields via updateField.
        onboardingCompleted,
        stripeRequiresInfo,
        stripeRequiresDocument,
        detailsSubmitted,
        oneFlowSigned,
        qrCode,
        ...company
      },
      ...state
    }: any) => ({ ...state, individual, company }),
  }).plugin(store);

  new VuexPersistence({
    storage: localStorage,
    filter: (mutation) => ['setJwt', 'setCountry'].includes(mutation.type),
    reducer: ({ country, jwt }: any) => ({ country, jwt }),
  }).plugin(store);
};
