// import { Context } from '@nuxt/vue-app';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
  configure,
  localeChanged,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules.umd';
import en from 'vee-validate/dist/locale/en.json';
import sv from 'vee-validate/dist/locale/sv.json';
import locEn from '../locales/en.json';
import locSv from '../locales/sv.json';
import Vue from 'vue';

// const veeEn = require('vee-validate/dist/locale/en');
// const veeSv = require('vee-validate/dist/locale/sv');

// export default ({ app }: Context) => {
export default ({ app }) => {
  // app.validator = VeeValidate.Validator;

  // const veeValidateOptions: { events: string, i18n?: any, dictionary?: object } = {
  const veeValidateOptions = {
    events: 'change|blur',
  };

  // extend('required', {
  //   validate() {
  //     // ...
  //   },
  //   // This rule reports the `required` state of the field.
  //   computesRequired: true
  // });

  extend('required', {
    ...rules.required,
    // message: 'This field is required'
  });

  extend('email', {
    ...rules.email,
    // message: 'This field must be a valid email'
  });

  extend('max', {
    ...rules.max,
    // message: 'This field's length must not exceed x characters'
  });

  extend('min', {
    ...rules.min,
    // message: 'This field's length must not be less than x characters'
  });

  localize({
    en,
    sv,
  });

  localize({
    en: locEn.validation,
    sv: locSv.validation,
  });

  // if (app.i18n) {
  // veeValidateOptions.i18n = app.i18n;
  // veeValidateOptions.dictionary = {
  //   en: veeEn,
  //   sv: veeSv,
  // };
  // }

  // app.i18n.onLanguageSwitched = () => {
  //   localeChanged();
  // }

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    console.log(oldLocale, newLocale);
    localize(newLocale);
  };

  localize(app.i18n.locale);

  // configure({
  //   defaultMessage: (field, values) => {
  //     console.log(values);
  //     // override the field name.
  //     values._field_ = app.i18n.t(`validation.attributes.${field}`);

  //     // return app.i18n.t(`validation.attributes.${values._rule_}`, values);
  //     return values._rule_;
  //   }
  // });

  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);
};
