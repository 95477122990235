var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "is-centered-with-shadow" },
        [_vm._m(0), _vm._v(" "), _c("nuxt")],
        1
      ),
      _vm._v(" "),
      _vm.showDebug
        ? _c("Debug", { staticClass: "has-text-centered" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "hero is-primary" }, [
      _c("img", {
        staticClass: "hero-logo",
        attrs: {
          src: require("assets/image/hero-logo.svg"),
          alt: "Tiptapp logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }