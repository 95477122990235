import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
  accountNumber: '',
  address: '',
  addressExtra: '',
  bankAccType: 'kontonummer',
  bankCountry: '',
  ctolCertify: false,
  wclCertify: false,
  city: '',
  birthplace: '',
  clearingNumber: '',
  code: '',
  email: '',
  firstname: '',
  iban: '',
  ibanTouched: false,
  canUploadIdDocuments: true,
  imageIdBack: '',
  imageIdFront: '',
  jwt: '',
  lastname: '',
  userImage: '',
  zipcode: '',
});

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setCanUploadIdDocuments(s, canUploadIdDocuments) {
    s.canUploadIdDocuments = canUploadIdDocuments;
  },
  setAddress(s, address) {
    s.address = address.line1;
    s.zipcode = address.zip;
    s.city = address.city;
  },
  setBankCountry(s, country) {
    s.bankCountry = country;
  },
  setBirthplace(s, birthplace) {
    s.birthplace = birthplace;
  },
  setFirstName(s, name) {
    s.firstname = name;
  },
  setLastName(s, name) {
    s.lastname = name;
  },
  setEmail(s, email) {
    s.email = email;
  },
  setIban(s, iban) {
    s.iban = iban;
    // s.ibanTouched = touched;
    // console.log('stateTouched', touched);
  },
  setBankAccType(s, type) {
    s.bankAccType = type;
  },
  setUserImage(s, imgUrl) {
    s.userImage = imgUrl;
  },
};

export const actions = {
  async login({ commit, rootState }, formData) {
    const { phone, code, country, userType } = rootState;

    const { authorization, accountLink, userId } = await this.$axios.$post(
      '/api/login/code/verify',
      {
        phone,
        code,
        userKind: userType == 'soletrader' ? 'soletrader' : 'person',
      },
      { headers: { 'x-tiptapp-country': country } }
    );
    commit('setJwt', authorization, { root: true });
    commit('setLoggedIn', true, { root: true });
    commit('setUserId', userId, { root: true });
    this.$router.push({ path: 'individual' });
  },
  async getAccount({ commit, state, rootState }, formData) {
    const { jwt: authorization, userId, country: rootCountry } = rootState;
    const { bankCountry } = state;
    const {
      firstname,
      lastname,
      email: sEmail,
      phone,
      iban: sIban,
      ibanTouched,
      birthplace: sBirthplace,
    } = state;

    const {
      name,
      email,
      address,
      country,
      birthplace,
      last4,
      details,
      document,
      additionalDocument,
      disabledReason,
      bankSetup,
      imgUrl,
    } = await this.$axios.$get(`/api/get-account`, {
      headers: { authorization, 'x-tiptapp-uid': userId },
    });

    const canUploadDocument = !['pending', 'verified'].includes(document?.status);
    const canUploadAdditionalDocument = !['pending', 'verified'].includes(
      additionalDocument?.status
    );

    commit('setCanUploadIdDocuments', canUploadDocument);
    commit('setFirstName', firstname || name?.f || '');
    commit('setLastName', lastname || name?.l || '');
    commit('setEmail', sEmail || email || '');
    if (address) {
      commit('setAddress', address);
    }
    commit('setBankCountry', bankCountry || rootCountry);
    commit('setBirthplace', sBirthplace || birthplace || '');
    if (sIban) {
      commit('setIban', sIban);
    } else if (last4) {
      commit('setIban', `•••• •••• •••• ${last4}`);
    }
    if (last4) {
      commit('setBankAccType', 'iban');
    }
    if (imgUrl) {
      commit('setUserImage', imgUrl);
    }
  },
  async submit({ commit, state, rootState }) {
    const { jwt: authorization, country, userId } = rootState;

    let {
      phone,
      code,
      address: line1,
      city,
      birthplace,
      zipcode: zip,
      firstname: fname,
      lastname: lname,
      email,
      iban,
      ibanTouched,
      bankCountry,
      accountNumber: bankAccount,
      clearingNumber: bankCode,
      wclCertify,
      ctolCertify,
      userType,
      userImage,
      imageIdFront,
      imageIdBack,
    } = state;

    let detailsPayload = {
      fname,
      lname,
      address: { line1, city, zip, country },
      birthplace,
      dob: { day: 1, month: 1, year: 1980 },
      bankCountry,
      userImageUri: userImage,
      email,
      ctolCertify,
      wclCertify,
    };

    if (imageIdFront || imageIdBack) {
      detailsPayload = {
        ...detailsPayload,
        imageFrontUri: imageIdFront,
        imageBackUri: imageIdBack,
      };
    }

    if (iban && ibanTouched) {
      detailsPayload.iban = iban;
    } else if (bankAccount && bankCode) {
      detailsPayload = { ...detailsPayload, bankAccount, bankCode };
    }

    try {
      const { accountLink } = await this.$axios.$post('/api/signup-individual', detailsPayload, {
        headers: { 'x-tiptapp-country': country, authorization, 'x-tiptapp-uid': userId },
      });

      if (accountLink) {
        commit('company/setAccountLink', accountLink);
      }

      this.$router.push({ path: 'success' });
    } catch (error) {
      console.log(error);
      this.$router.push({ path: 'error' });
    }
  },
};
