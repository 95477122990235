import { Context } from '@nuxt/types';

export default function ({ $axios, store }: Context) {
//   $axios.onRequest((config) => {
//     console.log('Making request to ' + config.url);
//   });

  $axios.onError((error) => {
    const code = error.response && error.response.status;
    if (code === 401) {
      store.commit('setLoggedIn', false);
    }
  });
}
