<template>
  <div class="container pb-6">
    <p class="pb-5">Development tools</p>

    <div class="block">
      <b-switch size="is-small" v-model="onboardingCompleted">Stripe verified</b-switch>
      <b-switch size="is-small" v-model="stripeConnectVisited">Stripe visited</b-switch>
      <b-switch size="is-small" v-model="stripeRequiresInfo">Stripe requires info</b-switch>
      <b-switch size="is-small" v-model="stripeRequiresDocument">Stripe requires documents</b-switch>
      <b-switch size="is-small" v-model="detailsSubmitted">Details submitted</b-switch>
      <b-switch size="is-small" v-model="oneFlowSigned">OneFlow signed</b-switch>
    </div>

    <b-field>
      <b-button
        v-if="$store.state.company.qrCode"
        tag="button"
        type="is-link is-small has-text-weight-bold"
        @click="showQrCode = true"
        >Show OneFlow QR code</b-button
      >
    </b-field>
    <b-field>
      <b-button tag="button" type="is-link is-small has-text-weight-bold" @click="clearContractId()"
        >Clear OneFlow Status</b-button
      >
    </b-field>

    <b-modal :active.sync="showQrCode">
      <img
        v-if="$store.state.company.qrCode"
        :src="'data:image/png;base64,' + $store.state.company.qrCode"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
      showQrCode: false,
    };
  },
  methods: {
    clearContractId: function () {
      this.$store.commit('company/setOneFlowContractId', '');
    },
  },
  computed: {
    ...mapFields('company', [
      'onboardingCompleted',
      'stripeConnectVisited',
      'stripeRequiresInfo',
      'stripeRequiresDocument',
      'detailsSubmitted',
      'oneFlowSigned',
    ]),
  },
};
</script>