<template>
  <div>
    <div class="is-centered-with-shadow">
      <!-- hero section -->
      <section class="hero is-primary">
        <img class="hero-logo" src="~assets/image/hero-logo.svg" alt="Tiptapp logo" />
      </section>

      <!-- main container -->
      <nuxt />
    </div>
    <Debug v-if="showDebug" class="has-text-centered" />
  </div>
</template>

<script>
import Debug from '~/components/DebugPane.vue';

export default {
  components: {
    Debug,
  },
  computed: {
    showDebug() {
      return process.env.devMode === 'true';
    }
  }
};
</script>

<style scoped>
.is-centered-with-shadow {
  margin: 0 auto;
  padding-bottom: 15px;
  max-width: 495px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.08);
}
@media (min-width: 495px) {
  .is-centered-with-shadow {
    margin-bottom: 50px;
  }
}
</style>
